export const about = {
  title: 'Missions, Values, Vision & Solution',
  nameImg: 'map.jpg',
  nameImgSlide: 'slide.jpg',
};

export const visions = [
  {
    title: 'Missions',
    content:
      'Make high quality products and provide best after-sell services for every customer to contribute to the development of Vietnam handicraft.',
    nameImg: 'mission.png',
    color: '#73c3f9',
  },
  {
    title: 'Values',
    content:
      'Passionately work for high quality products, always listen to customers for innovations & improvements as well as contribute for society to make life better.',
    nameImg: 'value.png',
    color: '#ff6379',
  },
  {
    title: 'Vision',
    content:
      'We aim to be the best handicraft manufacturer & exporter for customers who need a reliable partner for sustainable development.',
    nameImg: 'vision.png',
    color: '#6a95ba',
  },
  {
    title: 'Solution',
    content:
      'We are confident to satisfy any difficult customers who need best price solutions in business.',
    nameImg: 'solution.png',
    color: '#fbb040',
  },
];

export const paragraph = {
  title: 'About HandiPassion',
  icons: [
    {
      nameImg: 'eco-factory.png',
      color:
        'linear-gradient(90deg, rgba(119,202,101,1) 44%, rgba(128,218,162,1) 84%)',
      title: 'Production sites',
      number: '04',
      smallUnit: '',
      smallText: `
        Site 1: Trung Hoa commune, Chuong My district, Hanoi city </br>
        Site 2: Phu Nghia commune, Chuong My district, Hanoi city </br>
        Site 3: Binh Phu commune, Thach That district, Hanoi city </br>
        Site 4: Dong Huong commune, Kim Son district, Ninh Binh province
      `,
    },
    {
      nameImg: 'delivery-box.png',
      color:
        'linear-gradient(90deg, rgba(8,185,157,1) 26%, rgba(151,252,171,1) 84%)',
      title: 'Max capacity',
      number: '18',
      smallUnit: '40f containers/ month',
      smallText: `
      `,
    },
  ],
  paragraphs: [
    {
      content: `
        <p class='mb-6 font-about text-[1.6rem]'>Originated from Passion</p>
        Making handicraft products requires skillful techniques but the element that makes products special is passion. Passion plays a role in stimulating creativity and hard work, creativity combines with hard work to make best products. Our brand name “HandiPassion” with “Handi” that stands for Handicraft is our value to customers and society, “Passion” is our motivation pushing creativeness to make everlasting value. Wonderful products from over thirty years’ experience artisans of HandiPassion are made from not only meticulous skills but also inexhaustible passion.`,
      nameImage: '1.jpg',
    },
    {
      content: `
      <p class='mb-6 font-about text-[1.6rem]'>Belong to nature</p>
      Our products are made from water hyacinth, seagrass, rattan, bamboo, raffia palm, palm leaf, jute, corn husk & banana bark which are easy to be found around Vietnamese countryside where these plants attach to farmers’ lives on vast fields. Over time, Vietnamese farmers have used their skillful hands to make handicraft products from these simple materials which originate from nature and are very friendly to users. Our handicraft products are made of 100% natural materials with no harmful chemical, easily self-decomposed, safe and friendly to consumers including children & pregnant women. One of HandiPassion’s duty is to help customers feel nature is always around themselves with friendly & comfortable products.`,
      nameImage: '2.jpg',
    },
    {
      content: `
      <p class='mb-6 font-about text-[1.6rem]'>Quality makes us</p>
      We consider quality as the second critical element after pricings, the time customers cooperate with us depends on how good quality we manufacture products with. Controlling quality for handcrafted products is difficult, however, we have deep experience to do it easily by: we regularly conduct supplier audits and control quality for the production flow by performing Initial Production Check for incoming materials, During Production Check for 100% semi-products, Pre-shipment Inspection for finished products & Loading Supervision for shipment in accordance with ISO/IEC 17020: 2012. Strict control from the first steps to the final steps will bring good-quality products to achieve customer satisfaction.`,
      nameImage: '3.jpg',
    },
    {
      content: `
      <p class='mb-6 font-about text-[1.6rem]'>Not for us only, for others</p>
      Beside focusing on business, HandiPassion also cares about social activities. HandiPassion's business motto is “business is about not only profit but also social contribution”. We attach special importance to humanity in business with the desire to make good products. We have appropriation of our profit for donation to charity foundation/ activities every year. We always desire to do more and we are trying to grow up bigger and bigger to help more people in the future. We hope that small things we do are helpful to people around us. With HandiPassion, helping is a happiness.
      `,
      nameImage: '4.jpg',
    },
    {
      content: `
      <p class='mb-6 font-about text-[1.6rem]'>Our Story</p>
      We have been traditional families at handicraft villages in Northern Vietnam making handwoven products from rattan, bamboo, water hyacinth, seagrass & palm leaf since 1991 for domestic market. Chronologically, development of society and global export grew up, we have been manufacturing high quality handwoven products since 2007 and products we have manufactured have been globally exported. To meet high needs of global handicraft consumption, HandiPassion was established with a desire to create a handicraft manufacture brand for ourselves, for us to export high quality products with very competitive prices to wholesalers and retailers. We are serious about our business, our brand and our products. We deeply understand what customers need for their products, as a result, we focus on making reasonable pricings, good quality and good sales services for our customers. Sustainable development is our top target.`,
      nameImage: 'banner.jpg',
    },
  ],
};

export const customers = ['customer1.jpg','customer2.jpg','customer3.jpg','customer4.jpg', 'customer5.jpg','customer6.jpg'];
