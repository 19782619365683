export const video = {
  src: `https://www.youtube.com/embed/HGaI0mjjlcc?si=WU8ST2VMypSQIbj4`,
  caption: {
    content: `Established in the time of economic inflation after the Covid epidemic and war, 
    HandiPassion encountered many difficulties in exporting products to the world because of global decrease in demand, 
    economic recession and instability of global politics. However, those difficulties haven’t stopped our passion and efforts. 
    Passion for products that are natural, safe and imbued with national spirit. And the effort to bring high-quality products 
    with competitive prices to the world. We are growing day by day to become one of the best companies that manufactures and exports 
    handwoven products from natural materials. 
     <br /> `,
    button: {
      title: `ABOUT US`,
      to: `/about-us`,
    }
  }
}